<template>
    <div class="ship-info-container">
        <div class="button-section">
            <div class="search-item">
                <span class="search-title">阈值code：</span>
                <Input v-model="searchForm.gasCode" placeholder="请输入阈值code" style="width: 150px"></Input>
            </div>
            <div class="search-item">
                <span class="search-title">阈值名称：</span>
                <Input v-model="searchForm.gasName" placeholder="请输入阈值名称" style="width: 150px"></Input>
            </div>
            <div class="search-item">
                <span class="search-title">阈值：</span>
                <Input v-model="searchForm.gasValue" placeholder="请输入阈值" style="width: 150px"></Input>
            </div>
            <Button @click="searchHandler" style="margin-left: 20px" type="success" class="btn">搜索</Button>
            <Button @click="resetHandler" style="margin-left: 20px" type="default" class="btn">重置</Button>
            <Button type="success" class="btn create" @click="breakInputHandler">新增
            </Button>
        </div>
        <!-- 查询部分 -->
        <Table :loading="loading" :data="shipList" :columns="columns"> </Table>
        <Page @on-change="pageChange" @on-page-size-change="pageSizeChange" :total="total" size="small" show-total
            show-elevator show-sizer style="margin-top: 20px"></Page>
        <!-- 添加和修改窗口 -->
        <Modal :title="titleValue[dialogStatus]" v-model="detailModal" width="20%">
            <Form ref="formInline" :model="formInline" :rules="ruleInline" :label-width="70" hide-required-mark
                label-position="right">
                <FormItem v-for="item in itemcolumns" :key="item.key" :label="item.title" :prop="item.key">
                    <Input v-if="item.key != 'remark'" type="text" v-model="formInline[item.key]"
                        :placeholder="item.title" />
                    <Input v-else type="textarea" :autosize="{ minRows: 2, maxRows: 5 }" v-model="formInline[item.key]"
                        :placeholder="item.title" />
                </FormItem>
            </Form>
            <template #footer>
                <div class="btn-section">
                    <Button type="info" class="btn" @click="handleSubmit('formInline')">提交
                    </Button>
                    <Button style="margin-left: 20px" type="warning" class="btn" @click="handleClose('formInline')">取消
                    </Button>
                </div>
            </template>
        </Modal>
        <!-- 删除窗口 -->
        <Modal title="删除" v-model="deleteModal" width="20%">
            <div style="text-align: center; font-size: 15px">
                <p style="color: indianred">
                    <Icon style="font-size: large" type="ios-information-circle"></Icon>此操作将会清除该数据！
                </p>
                <p style="color: indianred">确认删除吗？</p>
            </div>
            <template #footer>
                <Button type="error" size="large" long @click="deleteSubmit()">确认</Button>
                <Button type="default" size="large" long style="margin: 2px 0" @click="deleteModal = false">
                    取消
                </Button>
            </template>
        </Modal>
    </div>
</template>

<script>
import {
    getGasInfoList,
    addGasInfo,
    updateGasInfo,
    deleteGasInfo,
} from "../../utils/api";
export default {
    data() {
        return {
            //删除样式
            deleteModal: false,
            // 新增和修改样式
            titleValue: {
                create: "新增",
                update: "修改",
            }, //title信息
            dialogStatus: "", //标题信息
            detailModal: false, //是否关窗

            // 新增和修改数据部分
            deleteid: "",
            formInline: {}, //表单数据
            ruleInline: {
                gasCode: [
                    {
                        required: true,
                        message: "请输入阈值code",
                        trigger: "blur",
                    },
                ],
                gasName: [
                    {
                        required: true,
                        message: "请输入阈值名称",
                        trigger: "blur",
                    },
                ],
                gasValue: [
                    {
                        required: true,
                        message: "请输入阈值",
                        trigger: "blur",
                    },
                ],
            }, //表单规则

            // 查询数据部分
            searchForm: {
                // gasCode: "",
                // gasName: "",
                // gasValue: "",
            }, //条件查询数据
            shipList: [], //表格数据
            loading: false, //是否处于加载
            total: 0, //总数
            columns: [
                {
                    title: "序号",
                    key: "num",
                    width: 70,
                },
                {
                    title: "阈值code",
                    key: "gasCode",
                    width: 200,
                },
                {
                    title: "阈值名称",
                    key: "gasName",
                    minWidth: 150,
                },
                {
                    title: "阈值",
                    key: "gasValue",
                    width: 150,
                },
                {
                    title: "备注",
                    key: "remark",
                    minWidth: 150,
                },
                {
                    title: "操作",
                    key: "action",
                    width: 100,
                    align: "center", //文字居中
                    render: (h, params) => {
                        return h("div", { class: "can-click" }, [
                            h(
                                "span",
                                {
                                    on: {
                                        click: () => {
                                            // console.log(params.row.id);
                                            this.handleUpdate(params.row);
                                        },
                                    },
                                },
                                "修改"
                            ),
                            h(
                                "span",
                                {
                                    on: {
                                        click: () => {
                                            this.handleDelete(params.row);
                                        },
                                    },
                                },
                                "删除"
                            ),
                        ]);
                    },
                },
            ], //表头
            pageSize: 10,
            pageNum: 1,
        };
    },
    created() {
        this.getGasInfoListAction(); //获得数据
    },
    computed: {
        itemcolumns: function () {
            return this.columns.filter(
                (item) => item.key != "action" && item.key != "num"
            );
        }, //去掉序号和操作字段
    },
    methods: {
        // 删除窗体方法
        handleDelete(row) {
            this.deleteModal = true;
            this.deleteid = row.id;
        },
        deleteSubmit() {
            deleteGasInfo([this.deleteid]).then((res) => {
                if (res.code == 0) {
                    this.$Message.success("操作成功!");
                    this.getGasInfoListAction(); //刷新
                    this.deleteModal = false; //关窗
                } else {
                    this.$Message.error(res.msg);
                }
            });
        },
        // 修改窗体方法
        handleUpdate(row) {
            this.formInline = Object.assign({}, row); //回显数据
            this.formInline.gasValue = parseInt(this.formInline.gasValue) + ""; //变为字符
            //console.log("aaa" + JSON.stringify(this.formInline));
            this.formInline.id = row.id; //加入id
            this.dialogStatus = "update"; //对应标题
            this.detailModal = true; //开窗
        },
        // 新增窗体方法
        breakInputHandler() {
            this.formInline = {}; //清空数据
            this.dialogStatus = "create"; //对应标题
            this.detailModal = true; //开窗
        },
        // 提交表单按钮方法
        handleSubmit(name) {
            this.$refs[name].validate((valid) => {
                //验证表单
                if (valid) {
                    switch (
                    this.dialogStatus //区分新增和修改
                    ) {
                        case "create": //新增
                            //console.log("add信息:" + JSON.stringify(this[name]));
                            addGasInfo(this[name])
                                .then((res) => {
                                    if (res.code == 0) {
                                        this.$Message.success("操作成功!");
                                        this.getGasInfoListAction(); //刷新
                                        this.detailModal = false; //关窗
                                    } else {
                                        this.$Message.error(res.msg);
                                    }
                                })
                                .catch((error) => {
                                    this.$Message.error(error.message + "!");
                                });
                            break;
                        case "update": //修改
                            // console.log("更新");
                            // console.log(this.formInline);
                            updateGasInfo(this.formInline)
                                .then((res) => {
                                    if (res.code == 0) {
                                        this.$Message.success("操作成功!");
                                        this.getGasInfoListAction();
                                        this.detailModal = false;
                                    } else {
                                        this.$Message.error(res.msg);
                                    }
                                })
                                .catch((error) => {
                                    this.$Message.error(error.message + "!");
                                });
                            break;
                        default:
                            break;
                    }
                } else {
                    this.$Message.error("操作失败!");
                }
            });
        },
        //表单取消按钮方法
        handleClose(name) {
            this.$refs[name].resetFields(); //重置操作
            this.formInline = {}; //清空数据
            this.detailModal = false; //关窗
        },

        // 查询方法
        resetHandler() {
            //条件查询重置
            this.pageSize = 10;
            this.pageNum = 1;
            this.searchForm = {};
            this.getGasInfoListAction();
        },
        searchHandler() {
            //条件查询
            this.getGasInfoListAction();
        },
        getGasInfoListAction() {
            let params = {
                pageNum: this.pageNum,
                pageSize: this.pageSize,
                gasThresholdDTO: {},
            }; //获取页
            if (this.searchForm.gasCode)
                params.gasThresholdDTO.gasCode = this.searchForm.gasCode;
            if (this.searchForm.gasName)
                params.gasThresholdDTO.gasName = this.searchForm.gasName;
            if (this.searchForm.gasValue)
                params.gasThresholdDTO.gasValue = this.searchForm.gasValue;
            //console.log(params.gasThresholdDTO);
            //console.log("预警阈值请求参数:" + JSON.stringify(params));
            getGasInfoList(params).then((res) => {
                //console.log("船舶气体预警阈值管理:" + JSON.stringify(res));
                if (res.code == 0) {
                    this.total = res.data.total;
                    let list = res.data.list;
                    list.forEach((item, index) => {
                        item.gasValue = item.gasValue + "(mg/m3)"; //加单位
                        item.num = index + 1; //加序号
                    });
                    this.shipList = list;
                }
            });
        },
        pageChange(num) {
            this.pageNum = num;
            this.getGasInfoListAction();
        },
        pageSizeChange(size) {
            this.pageSize = size;
            this.getGasInfoListAction();
        },
    },
};
</script>

<style lang="scss">
.ship-info-container {
    height: 100%;
    padding: 30px;
    text-align: center;

    .button-section {
        display: flex;
        padding-bottom: 20px;
        justify-content: flex-start;

        .search-item {
            margin-left: 20px;

            &:first-child {
                margin-left: 0;
            }

            .search-title {
                font-size: 16px;
                color: #fff;
            }
        }

        .btn {
            margin-left: 10px;

            &:first-child {
                margin-left: 0;
            }

            &:last-child {
                margin-left: auto;
                margin-right: 20px;
            }
        }
    }
}

.can-click span {
    cursor: pointer;
}
</style>
